import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import 'typeface-roboto';
import Cookies from 'universal-cookie';
import {
  Button,
  Card,
  CardContent,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  Slide,
  TextField,
  Theme,
  ListItemIcon,
  Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import './types.d'
import InputNumber from 'rc-input-number'; // eslint-disable-line
import 'rc-input-number/assets/index.css'
import { TransitionProps } from '@material-ui/core/transitions';
import logo from './WideCart_Logotext.png';
import background from './WideCart_BG.png'
import Div100vh from 'react-div-100vh'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cookies = new Cookies();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#05386B',
    },
  },
});

interface Item {
  name: string;
  quantity: number;
}

function App() {
  const classes = useStyles();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Div100vh className={classes.container}>
            <Div100vh style={{ height: '13rvh', paddingTop: '2rvh', }}>
                <header className={classes.app_header}>
                  <Grid
                    container
                    style={{height: '100%'}}
                  >
                    <Grid item md={2}  xs={'auto'}>
                    </Grid>
                    <Grid container justify="center" item md={8}  xs={12} style={{height: '100%'}}>
                        <img src={logo} alt="Logo" className={classes.logo} />
                    </Grid>
                    <Grid item md={2} xs={'auto'}>
                      <a href="https://www.wideeyetours.com/" target="_blank" style={{textDecoration: 'none', color: '#1A1463'}}>
                        <h3 className={classes.tours_link}>Wide-Eye Tours</h3>
                      </a>
                    </Grid>
                  </Grid>
                </header>
            </Div100vh>
            <Switch>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
        </Div100vh>
      </ThemeProvider>
    </Router>
  );
}

function Home() {
  const classes = useStyles();

  return (
    <Div100vh className={classes.content} style={{ height: '85rvh'}}>
      <CartComponent initialItems={getItemsFromCookies()}/>
      <a href="https://www.wideeyetours.com/" target="_blank" style={{textDecoration: 'none', color: '#1A1463'}}>
        <h4 className={classes.tours_link_mobile}>Wide-Eye Tours</h4>
      </a>
    </Div100vh>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getItemsFromCookies() {
  const storedCart = cookies.get('items');
  return storedCart || [];
}

function updateItemsInCookies(items: Item[]) {
  cookies.set('items', items, { path: '/' });
}

interface FormErrors {
  'name': string;
  'email': string;
  'company': string;
  'phoneNumber': string;
}

const initialFormErrors: FormErrors = {
  'name': '',
  'email': '',
  'company': '',
  'phoneNumber': '',
};

type FieldName = 'name' | 'email' | 'company' | 'phoneNumber';

function CartComponent(props: {initialItems: Item[]}) {
  const classes = useStyles();
  const [items, setItems] = useState(props.initialItems)
  const [open, setOpen] = useState(false);
  const [vendor, setVendor] = useState('');
  let query = useQuery();
  let location = useLocation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const newItemName = query.get("item");
    if (newItemName) {
      addItem(newItemName);
    }
    setVendor(location.pathname.split('/')[1].split('_').join(' '));
  }, []) // eslint-disable-line

  function updateAndPersist(items: Item[]) {
    setItems(items);
    updateItemsInCookies(items);
  }

  function addItem(itemName: string) {
    let isItemNew = true;

    const newItems = items.map((item: Item) => {
      if ( item.name === itemName ) {
        isItemNew = false;
        return {
          ...item,
          quantity: item.quantity + 1,
        }
      }

      return item;
    });

    if (isItemNew) {
      newItems.push({
        quantity: 1,
        name: itemName,
      })
    }

    updateAndPersist(newItems);
  }

  function removeItem(itemName: string) {
    return () => updateAndPersist(items.filter((item: Item) => item.name !== itemName));
  }

  function handleQuantityChange(itemName: string) {
    return (value: number) => setQuantity(itemName, value);
  }

  function setQuantity(itemName: string, newQuantity: number) {
    const newItems = items.map((item: Item) => item.name === itemName ? {
      ...item,
      quantity: newQuantity
    }: item);

    updateAndPersist(newItems);
  }

  function renderItems() {
    return items.map((item: Item, index: number) => <ItemComponent itemName={item.name} key={index} remove={removeItem(item.name)} quantity={item.quantity} handleQuantityChange={handleQuantityChange(item.name)}/>)
  }

  function clearCart() {
    updateAndPersist([]);
  }

  return (
    <>
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="center"
    >
      <Div100vh style={{ marginTop: '1.5rvh' }}>
        <Card className={classes.card}>
          <CardContent style={{ marginBottom: 20 }}>
            <Div100vh style={{ height: '60rvh' }}>
            {vendor ? 
            <Typography gutterBottom variant="h5" component="h2" className={classes.vendor_name}>
              {vendor}
            </Typography>
            : null
            }
              <List className={classes.list}>
                {renderItems()}
              </List>
            </Div100vh>
          </CardContent>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Button onClick={clearCart} variant="contained" color="primary" type="submit">Clear Cart</Button>
            <Button onClick={handleClickOpen} variant="contained" color="primary" >Email Cart</Button>
          </Grid>
        </Card>
      </Div100vh>
    </Grid>
    <FormDialog open={open} handleClose={handleClose} items={items} vendor={vendor} />
    </>


  )
}

function ItemComponent(props: {itemName: string, quantity: number, handleQuantityChange: (value: number) => void, remove: () => void}) {
  const { itemName, remove, quantity } = props
  const classes = useStyles();

  return (
    <Card className={classes.list_item} elevation={3}>
      <ListItem>
        <ListItemIcon style={{marginRight: 30}}>
        <InputNumber
          min={0}
          max={99}
          style={{ width: 60 }}
          value={quantity}
          onChange={props.handleQuantityChange}
        />
        </ListItemIcon>
        <ListItemText
          primary={itemName}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={remove}>
            <ClearIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Card>
  );
}

function FormDialog(props: {handleClose: () => void, items: Item[], open: boolean, vendor: string}) {
  const {handleClose, items, open, vendor} = props;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors] = useState<FormErrors>(initialFormErrors);

  function handleSubmit(event: any) {
    event.preventDefault()

    const newErrors= {
      ...initialFormErrors
    };
    let isValid = true;
    if (!name) {
      newErrors['name'] = "This is a required field";
      isValid = false;
    }
    if (!email) {
      newErrors['email'] = "This is a required field";
      isValid = false;
    }
    setErrors(newErrors);
    if (isValid) {
      sendEmail();
    }
  }

  function sendEmail() {
    handleClose();
    const payload = {
      items,
      customer: {
        name,
        email,
        company,
        phoneNumber,
      },
      vendor,
    }
    axios.post('https://us-central1-wide-eyes-ca66b.cloudfunctions.net/sendCartEmail', JSON.stringify(payload), {headers: {'Content-Type': 'application/json'}});
  }

  function handleChange(event: any) {
    const input = event.target.value;
    const name = event.target.name as FieldName;
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      })
    }

    switch(name) {
      case 'email':
        setEmail(input);
        break;
      case 'name':
        setName(input);
        break;
      case 'company':
        setCompany(input);
        break;
      case 'phoneNumber':
        setPhoneNumber(input);
        break;
      default:
        break;
    }
  }


  return (
    <div>
      <Dialog 
        fullWidth={window.innerWidth < 760}
        maxWidth="lg"
        open={open} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">Your contact information</DialogTitle>
          <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            We will email you a copy of your cart.
          </DialogContentText>
          <Div100vh style={{ minHeight: '50rvh', minWidth: '30vw' }}>
            <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="center"
            >
              <TextField
                error={Boolean(errors['name'])}
                fullWidth
                helperText={errors['name']}
                label="Name"
                name={'name'}
                onChange={handleChange}
                value={name}
              />
              <TextField
                error={Boolean(errors['email'])}
                fullWidth
                helperText={errors['email']}
                label="Email Address"
                name={'email'}
                onChange={handleChange}
                value={email}
              />
              <TextField
                error={Boolean(errors['company'])}
                fullWidth
                helperText={errors['company']}
                label="Company"
                name={'company'}
                onChange={handleChange}
                value={company}
              />
              <TextField
                error={Boolean(errors['phoneNumber'])}
                fullWidth
                helperText={errors['phoneNumber']}
                label="Phone Number"
                name={'phoneNumber'}
                onChange={handleChange}
                value={phoneNumber}
              />
            </Grid>
          </Div100vh>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" type="submit">
            Send Cart
          </Button>
        </DialogActions>
      </form>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
  container: {
      backgroundColor: '#5CDB95',
    [theme.breakpoints.up('md')]: {
      backgroundImage:`url(${background})`,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat'
    },
  },
  app_header: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#5CDB95',
    },
    minWidth: '100vw',
    color: '#1A1463',
    height: '100%',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#5CDB95',
    },
    minWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'black',
  },
  card: {
    backgroundColor: '#EDF5E1',
    padding: 20,
    maxWidth: '80vw',
  },
  list_item: {
    marginBottom: 20,
  },
  list: {
    height: '100%',
    minWidth: '40vw',
    overflow: 'scroll',
    marginBottom: 20,
    padding: 10,
  },
  logo: {
    maxWidth: '80vw',
    [theme.breakpoints.down('sm')]: {
      height: '50%'
    },
    height: '100%',
    paddingLeft: 10,
  },
  tours_link: {
    fontFamily: 'Futura-ExtraBold',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tours_link_mobile: {
    fontFamily: 'Futura-ExtraBold',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    marginTop: 40,
  },
  vendor_name: {
    fontFamily: 'Futura-ExtraBold',
    textAlign: 'center',
    color: '#1A1463',
    fontSize: 24,
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
  },
}));


export default App;




